var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-5" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass:
                  "font-weight-bold main-title\n         text-center text-capitalize",
              },
              [_vm._v(" " + _vm._s(_vm.$t("HowToVideos")))]
            ),
          ]),
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: { cols: "12", sm: "6", "offset-sm": "3" },
            },
            [
              _c(
                "p",
                { staticClass: "text-center u-blue-text font-family-rubik" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("WehaveCreatedASeriesOfHowToVideos")) +
                      " "
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToSelfEnrollVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [_vm._v(" " + _vm._s(_vm.$t("HowToSelfEnroll")) + " ")]
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToBookAnAppointmentWithUcarMobileAppVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("HowToBookAnAppointmentWithUcarMobileApp")) +
                    " "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToBookMechanicalRepairAppointmentsVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("HowToBookMechanicalRepairAppointments")) +
                    " "
                ),
              ]
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToViewYourRepairOrderVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [_vm._v(" " + _vm._s(_vm.$t("HowToViewYourRepairOrder")) + " ")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToApproveAnEstimateSentByYourMechanicVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("HowToApproveAnEstimateSentByYourMechanic")) +
                    " "
                ),
              ]
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToGiveAReviewOnUcarVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("HowToGiveAReviewOnUcarMobile")) + " "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToSendARepairEstimateVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [_vm._v(" " + _vm._s(_vm.$t("HowToSendARepairEstimate")) + " ")]
            ),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6", "offset-sm": "0" } }, [
            _c("iframe", {
              staticClass: "embed-responsive-item",
              staticStyle: { width: "100%" },
              attrs: {
                height: "350",
                src: _vm.$t("HowToBillTheClientVideo"),
                allowfullscreen: "",
              },
            }),
            _c(
              "p",
              { staticClass: "text-center u-blue-text font-family-rubik" },
              [_vm._v(" " + _vm._s(_vm.$t("HowToBillTheClient")) + " ")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }